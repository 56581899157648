import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

export default function FeaturePage() {
  const [clientSecret, setClientSecret] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  // When the page loads, create a Payment Intent and save its client secret
  useEffect(() => {
    fetch('http://localhost:5000/create-payment-intent', { // Replace with your server's address
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        amount: 1000 // Replace with the cost of the feature
      })
    })
    .then(response => response.json())
    .then(data => setClientSecret(data.clientSecret))
    .catch((error) => console.error('Error:', error));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return; // Stripe has not loaded yet, make sure to disable form submission until Stripe has loaded
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'John Doe' // Replace with the name of the user
        },
      }
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        console.log('Payment succeeded!');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button disabled={!stripe}>Pay</button>
    </form>
  );
}
