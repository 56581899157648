import React from 'react';
import './App.css';
import AppRouter from './AppRouter'; // Import the AppRouter component

function App() {
  return (
    <div className="App">
      <AppRouter /> {/* Use the AppRouter component */}
    </div>
  );
}

export default App;
