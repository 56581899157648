import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import LandingPage from './LandingPage';
import RegistrationPage from './RegistrationPage';
import DownloadPage from './DownloadPage';
import LoginPage from './LoginPage';
import FeaturePage from './FeaturePage'; // Import the FeaturePage
import Cookies from 'js-cookie';

function AppRouter() {
  const [isLoggedIn, setIsLoggedIn] = useState(!!Cookies.get('sessionId'));

  const handleLogout = () => {
    Cookies.remove('sessionId');
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <div>
        <nav>
          <AppBar position="static">
            <Toolbar>
              <ul style={{ listStyle: "none", display: "flex", margin: 0, padding: 0 }}>
                <li>
                  <Button color="inherit"><Link to="/">Home</Link></Button>
                </li>
                <li>
                  <Button color="inherit"><Link to="/themes">Theme Browser</Link></Button>
                </li>
              </ul>
              <Typography variant="h6" sx={{ flexGrow: 1 }}></Typography>
              <ul style={{ listStyle: "none", display: "flex", margin: 0, padding: 0 }}>
                {isLoggedIn ? (
                  <>
                    <li>
                      <Button color="inherit"><Link to="/features">Features</Link></Button>
                    </li>
                    <li>
                      <Button color="inherit" onClick={handleLogout}>Logout</Button>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Button color="inherit"><Link to="/login">Login</Link></Button>
                    </li>
                    <li>
                      <Button color="inherit"><Link to="/register">Register</Link></Button>
                    </li>
                  </>
                )}
              </ul>
            </Toolbar>
          </AppBar>
        </nav>

        <Routes>
          <Route path="/login" element={<LoginPage setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/register" element={<RegistrationPage setIsLoggedIn={setIsLoggedIn} />} />
          <Route path="/features" element={<FeaturePage />} /> {/* Add the route for FeaturePage */}
          <Route path="/download" element={<DownloadPage />} />
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default AppRouter;
