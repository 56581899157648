import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import logo from './GridFinity_Label_Maker_Icon.png';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// Mockup of popular themes, replace it with real data
const popularThemes = [
    {
        title: 'Theme 1',
        image: 'https://via.placeholder.com/150',
    },
    {
        title: 'Theme 2',
        image: 'https://via.placeholder.com/150',
    },
    {
        title: 'Theme 3',
        image: 'https://via.placeholder.com/150',
    },
    {
        title: 'Theme 4',
        image: 'https://via.placeholder.com/150',
    },
    {
        title: 'Theme 5',
        image: 'https://via.placeholder.com/150',
    },
    {
        title: 'Theme 6',
        image: 'https://via.placeholder.com/150',
    },
    {
        title: 'Theme 7',
        image: 'https://via.placeholder.com/150',
    },
    {
        title: 'Theme 8',
        image: 'https://via.placeholder.com/150',
    },
];

const appScreenshots = [
    'https://via.placeholder.com/400',
    'https://via.placeholder.com/400',
    'https://via.placeholder.com/400',
    'https://via.placeholder.com/400',
    'https://via.placeholder.com/400',
    'https://via.placeholder.com/400',
];

function LandingPage() {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: { xs: '0 2rem', sm: '0 5rem' }, // increased horizontal padding on larger screens
            textAlign: 'center',
            mt: { xs: '2rem', sm: '5rem', md: '10rem' }, // increased margin-top on larger screens
            maxWidth: '1200px', // Limit the max width of the content
            margin: 'auto', // Center the content horizontally
        }}>
            <img src={logo} alt="Gridfinity Logo" style={{ width: '150px', height: 'auto' }} />
            <Typography variant="h2" component="div" gutterBottom>
                Welcome to Gridfinity Label Maker
            </Typography>
            <Typography variant="h5" component="div" gutterBottom>
                Create and customize labels with ease, share them, and even sell your designs!
            </Typography>
            <Typography variant="h6" component="div" gutterBottom>
                Download our application and start creating for free! No registration required for free themes.
            </Typography>
            <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', justifyContent: 'center', marginTop: '1rem' }}>
                <Button variant="contained" color="primary" component={Link} to="/download">Download App</Button>
                <Button variant="contained" color="secondary" component={Link} to="/register">Register</Button>
                {/* <Button variant="outlined" color="primary" component={Link} to="/login">Login</Button> */}
                <Button variant="outlined" color="secondary" component={Link} to="/themes">Browse Themes</Button>
            </Box>
            <Box sx={{ width: '100%', marginTop: '3rem', marginBottom: '1rem' }}>
                <Typography variant="h4" component="div" gutterBottom>
                    App Screenshots
                </Typography>
                <Box sx={{ width: '100%', maxWidth: '450px', height: 'auto', maxHeight: '550px', margin: '0 auto' }}>
                    <Carousel
                        showThumbs={false}
                        dynamicHeight
                        emulateTouch
                        infiniteLoop
                        useKeyboardArrows
                        autoPlay
                        stopOnHover
                        swipeable
                    >
                        {appScreenshots.map((src, index) => (
                            <div key={index}>
                                <img
                                    src={src}
                                    alt={`App Screenshot ${index + 1}`}
                                    style={{ width: '100%', borderRadius: '10px' }}
                                />
                            </div>
                        ))}
                    </Carousel>
                </Box>
            </Box>
            <Box sx={{ width: '100%', marginTop: '3rem', paddingBottom: '2rem' }}>
                <Typography variant="h4" component="div" gutterBottom>
                    Popular Themes
                </Typography>
                <Grid container spacing={2} justifyContent="center">
                    {popularThemes.map((theme, index) => (
                        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={theme.image}
                                    alt={theme.title}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {theme.title}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}

export default LandingPage;

