import React, { useState } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false); // new state variable
  const navigate = useNavigate(); // for redirection

  const handleSubmit = (event) => {
    event.preventDefault();

    // Send a POST request to your backend
    fetch('http://localhost:5000/login', { // Replace with your server's address
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password
      }),
      credentials: 'include' // This is required for cookies to be sent
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Error: ' + response.statusText);
      }
    })
    .then(data => {
      Cookies.set('sessionId', data.sessionId);
      setIsLoggedIn(true); // user is logged in
    })
    .catch(error => console.error('Error:', error));
  };

  // Redirect user to homepage after login
  if (isLoggedIn) {
    navigate('/');
  }

  return (
    <div>
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Username:
          <input type="text" name="username" value={username} onChange={e => setUsername(e.target.value)} />
        </label>
        <label>
          Password:
          <input type="password" name="password" value={password} onChange={e => setPassword(e.target.value)} />
        </label>
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
}

export default LoginPage;
