import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import logo from './GridFinity_Label_Maker_Icon.png';

function DownloadPage() {
  const [loading, setLoading] = useState(true);
  const [downloadUrl, setDownloadUrl] = useState(null);

  useEffect(() => {
    axios({
        url: 'https://gf-lm.com/updates/GridfinityLMSetup.exe',
        method: 'GET',
        responseType: 'blob',
    })
    .then((response) => {
        const file = new Blob(
            [response.data], 
            {type: 'application/octet-stream'}
        );
        const url = URL.createObjectURL(file);
        setDownloadUrl(url);
        setLoading(false);
    });
  }, []);

  return (
    <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
        padding: { xs: '2rem', sm: '5rem' },
        textAlign: 'center',
        mt: { xs: '2rem', sm: '5rem', md: '10rem' },
        maxWidth: '1200px',
        margin: 'auto',
    }}>
      <img src={logo} alt="Gridfinity Logo" style={{ width: '150px', height: 'auto' }} />
      <Typography variant="h2" component="div" gutterBottom>
        Gridfinity Label Maker Download
      </Typography>
      <Typography variant="h6" component="div" gutterBottom>
      {loading ? (
        <>Gathering Information...</>
      ) : (
        <>Your download is ready!</>
      )}
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button variant="contained" color="primary" href={downloadUrl} download="GridfinityLMSetup.exe">
          Download Now
        </Button>
      )}
    </Box>
  );
}

export default DownloadPage;
